import {forwardRef, useState} from "react";

type Props = {
    disabled?: boolean;
    readOnly?: boolean;
    value?: string;
};

type RevealablePasswordRef = HTMLInputElement;

const RevealablePassword = forwardRef<RevealablePasswordRef, Props>(
    ({disabled, readOnly, value, ...rest}, ref) => {
        const [isFocused, setFocus] = useState(false);

        const shownValue =
            isFocused || !value || value.length === 0
                ? value
                : new Array(value.length + 1).join("\u2022");

        return (
            <input
                {...rest}
                readOnly={readOnly || disabled}
                ref={ref}
                onFocus={({target}) => {
                    setFocus(true);
                    if (readOnly) {
                        target.select();
                    }
                }}
                onBlur={() => {
                    setFocus(false);
                    window.getSelection()?.removeAllRanges();
                }}
                type="text"
                value={shownValue}
                data-hidden-value={value}
            />
        );
    }
);

RevealablePassword.displayName = "RevealablePassword";

export default RevealablePassword;
