import {Fragment} from "react";

import {Row} from "components/common/Grid";
import Logo from "components/common/Logo";
import getEnv from "lib/getEnv";
import {LoginFormValues, LoginHandlerFormProps, LoginProps, LoginState} from "../Login";

const no_org = /[?&]no_org/;

function RegisterForm({children}: LoginHandlerFormProps) {
    const hideOrg = window.location.search.match(no_org);
    return (
        <Fragment key="register-form">
            <Row className="align-up">
                <Logo>{getEnv("package")}</Logo>
            </Row>

            <Row>
                <div className="login-page-form">
                    <Row className="center">
                        <div className="login-input">
                            <input
                                autoFocus
                                className="input"
                                placeholder="Email"
                                name="username"
                                type="email"
                            />
                        </div>
                    </Row>
                    {!hideOrg && (
                        <Row className="center">
                            <div className="login-input">
                                <input
                                    className="input"
                                    placeholder="Organization name"
                                    name="organization"
                                    type="text"
                                />
                            </div>
                        </Row>
                    )}
                    <Row className="center">
                        <div className="login-input">
                            <input
                                className="input"
                                placeholder="Password"
                                name="password"
                                type="password"
                            />
                        </div>
                    </Row>
                    <Row className="center">
                        <div className="login-input">
                            <input
                                className="input"
                                placeholder="Enter password again"
                                name="re_password"
                                type="password"
                            />
                        </div>
                    </Row>
                    {children("Register")}
                </div>
            </Row>
        </Fragment>
    );
}

type CreateAccountParams = {
    username: string;
    password: string;
    organization_name: string;
    invite_token?: string;
};

const createAccount = async (
    authUrl: string,
    {username, password, organization_name, invite_token}: CreateAccountParams
) => {
    let resp;
    try {
        resp = await fetch(`${authUrl}/register`, {
            credentials: "same-origin",
            method: "POST",
            body: JSON.stringify({username, password, organization_name, invite_token}),
            headers: {
                "Content-Type": "application/json"
            }
        });
    } catch (e) {
        throw {message: "Could not connect to server"};
    }

    if (!resp.ok) {
        const e = await resp.json();
        throw {...e, ...e.errors?.[0]};
    }
};

export default {
    Form: RegisterForm,
    getParams: async (
        formVals: LoginFormValues,
        state: LoginState,
        props: LoginProps
    ) => {
        if (!formVals.password || formVals.password !== formVals.re_password) {
            throw "Passwords do not match";
        }

        const username = formVals.username;
        const password = formVals.password;
        const organization_name = formVals.organization ?? "";

        if (!username) {
            throw "Username is required";
        }

        await createAccount(props.authUrl, {
            username,
            password,
            organization_name,
            invite_token: state.referral
        });

        return {username, password};
    }
};
