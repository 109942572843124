import {ComponentPropsWithoutRef, forwardRef, ReactNode} from "react";

const cssClasses = (arr: string[]) => arr.filter(s => s).join(" ");

type RowProps = ComponentPropsWithoutRef<"div"> & {
    className?: string;
    children?: ReactNode;
    margin?: boolean;
    padding?: boolean;
};

type RowRef = HTMLDivElement;
const Row = forwardRef<RowRef, RowProps>(
    ({className = "", children, style, margin, padding, ...props}, ref) => {
        const classes = [
            "grid-x",
            margin ? "grid-margin-x" : "",
            padding ? "grid-padding-x" : "",
            className
        ];
        return (
            <div ref={ref} className={cssClasses(classes)} {...props}>
                {children}
            </div>
        );
    }
);

Row.displayName = "Row";

export {Row};

type CellProps = ComponentPropsWithoutRef<"div"> & {
    className?: string;
    children?: ReactNode;
    large?: number;
    medium?: number;
    small?: number;
    isShrunk?: boolean;
};

export function Cell({
    className = "",
    children,
    small,
    medium,
    large,
    isShrunk,
    ...props
}: CellProps) {
    const sizes = {small, medium, large};
    const sizeClasses = ["small", "medium", "large"]
        .filter(s => sizes[s as keyof typeof sizes])
        .map(s => `${s}-${sizes[s as keyof typeof sizes]}`);

    if (isShrunk) {
        sizeClasses.push("shrink");
    }

    const auto = sizeClasses.length === 0 ? "auto" : "";

    const classes = ["cell", className, auto, ...sizeClasses];
    return (
        <div className={cssClasses(classes)} {...props}>
            {children}
        </div>
    );
}
