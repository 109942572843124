import {ReactNode} from "react";
import SVG from "react-inlinesvg";

import "./Logo.scss";

type Props = {
    children?: ReactNode;
    className?: string;
    tag?: ReactNode;
};

export default function Logo({children, className, tag}: Props) {
    return (
        <div className={"logo" + (className ? " " + className : "")}>
            <SVG src="img/logo.svg" description="Logo" />
            {children && <h1>{children}</h1>}
            {tag && <div className="separator" />}
            {tag && <h2>{tag}</h2>}
        </div>
    );
}
