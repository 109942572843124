import {ChangeEvent, Fragment} from "react";

import {Row} from "components/common/Grid";
import RevealablePassword from "components/common/RevealablePassword";

import SecondFactorLogo from "../SecondFactorLogo";
import getEnv from "lib/getEnv";
import QRCode from "qrcode.react";
import {
    LoginHandlerError,
    LoginHandlerFormProps,
    LoginState
} from "components/auth/Login";

type PairingCodeProps = {
    secret: string;
    username: string;
};

const PairingCode = ({secret, username}: PairingCodeProps) => {
    const host = encodeURIComponent(`${getEnv("package")} (${window.location.hostname})`);
    const value = `otpauth://totp/${host}:${username}?secret=${secret}&issuer=ArcticSecurity`;
    return (
        <a href={value}>
            <QRCode size={150} value={value} bgColor="#fff" fgColor="#00a1f2" />
        </a>
    );
};

function SecondFactorForm({secret, username, submit, children}: LoginHandlerFormProps) {
    const autoSubmit = async ({target}: ChangeEvent<HTMLInputElement>) => {
        const {value} = target;
        const form = target.closest("form");
        if (form && value.length === 6 && Number(value)) {
            await submit({target: form});
        }
    };

    return (
        <Fragment key="2fa-form">
            {!secret && (
                <Row className="align-up">
                    <SecondFactorLogo className="logo" />
                </Row>
            )}
            <Row className={"center twofactor" + (secret ? " pairing" : "")}>
                {username && secret && (
                    <Fragment>
                        <div className="title">Enable two-factor authentication</div>
                        <PairingCode username={username} secret={secret} />
                        <div className="secret">
                            <RevealablePassword readOnly value={secret} />
                        </div>
                        <div className="body-text">
                            Scan the QR code with your chosen application to set up
                            authentication
                        </div>
                    </Fragment>
                )}

                {!secret && (
                    <Fragment>
                        <div className="title">Enter a verification code</div>
                        <div className="body-text">
                            Get a verification code from two factor authenticator app
                        </div>
                    </Fragment>
                )}
            </Row>
            <Row>
                <div className="login-page-form">
                    <Row className="center">
                        <div className="login-input">
                            <input
                                autoFocus={true}
                                className="input"
                                onChange={autoSubmit}
                                placeholder="Enter the 6-digit code"
                                autoComplete="off"
                                name="totp"
                                type="text"
                            />
                        </div>
                    </Row>
                    {children("Confirm")}
                </div>
            </Row>
        </Fragment>
    );
}

export default {
    Form: SecondFactorForm,
    handleError: (err: LoginHandlerError, prevState: LoginState) => {
        const nextState: Partial<LoginState> = {};
        if (!prevState.totp) {
            // hide the "2 factor required" error message when first moving from login form to 2fa entering
            nextState.error = void 0;
        }

        if (err.secret) {
            nextState.secret = err.secret;
        }

        return nextState;
    }
};
