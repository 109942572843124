import "./SecondFactorLogo.scss";

type Props = {
    className?: string;
    color?: string;
};

export default function SecondFactorLogo({className, color}: Props) {
    const styles = color ? {backgroundColor: color} : {};
    return (
        <div
            className={"second-factor-logo" + (className ? " " + className : "")}
            style={styles}
        />
    );
}
