import {Fragment} from "react";

import {Row} from "components/common/Grid";
import Logo from "components/common/Logo";
import getEnv from "lib/getEnv";
import {
    LoginFormValues,
    LoginHandlerError,
    LoginHandlerFormProps,
    LoginProps,
    LoginQueryArgs,
    LoginState
} from "../Login";

function PasswordChangeForm({children}: LoginHandlerFormProps) {
    return (
        <Fragment key="change-password-form">
            <Row className="align-up">
                <Logo>{getEnv("package")}</Logo>
            </Row>

            <Row>
                <div className="login-page-form">
                    <Row className="center">
                        <div className="login-input">
                            <input
                                className="input"
                                placeholder="Enter new Password"
                                name="new_password"
                                type="password"
                            />
                        </div>
                    </Row>
                    <Row className="center">
                        <div className="login-input">
                            <input
                                className="input"
                                placeholder="Enter new password again"
                                name="re_new_password"
                                type="password"
                            />
                        </div>
                    </Row>
                    {children("Change password")}
                </div>
            </Row>
        </Fragment>
    );
}

const changePassword = async (
    authUrl: string,
    {username, old_password, new_password, totp}: $TSFixMe
) => {
    let resp;
    try {
        resp = await fetch(`${authUrl}/change-password`, {
            credentials: "same-origin",
            method: "POST",
            body: JSON.stringify({old_password, new_password, totp, username}),
            headers: {
                "Content-Type": "application/json"
            }
        });
    } catch (e) {
        throw {message: "Could not connect to server"};
    }

    if (!resp.ok) {
        const e = await resp.json();
        throw {...e, ...e.errors?.[0], new_password};
    }
};

export default {
    Form: PasswordChangeForm,
    getParams: async (
        formVals: LoginFormValues,
        state: LoginState,
        props: LoginProps
    ) => {
        if (formVals.new_password !== formVals.re_new_password) {
            throw "Passwords do not match";
        }

        const username = state.username;
        const new_password = formVals.new_password ?? state.new_password;
        const password = new_password;

        if (!password || new_password.trim().length === 0) {
            throw "Empty password not allowed";
        }

        if (!username) {
            throw "Username is required";
        }

        await changePassword(props.authUrl, {
            username,
            new_password,
            old_password: state.old_password,
            totp: formVals.totp
        });

        const creds: LoginQueryArgs = {username, password};
        if (formVals.totp) {
            creds.totp = formVals.totp;
        }
        return creds;
    },
    handleError: (error: LoginHandlerError, prevState: LoginState) => {
        if (error.type === "password_change") {
            return {
                password: void 0,
                old_password: prevState.password,
                step: "password_change"
            };
        } else if (error.new_password) {
            return {new_password: error.new_password};
        }
    }
};
