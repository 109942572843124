import {Fragment, MouseEvent, useRef} from "react";

import {Row} from "components/common/Grid";
import Logo from "components/common/Logo";
import getEnv from "lib/getEnv";
import {LoginHandlerError, LoginHandlerFormProps} from "../Login";

const isEWS = getEnv("package") === "EWS";

function LoginForm({children, setState, counter}: LoginHandlerFormProps) {
    const inputRef = useRef<HTMLInputElement>(null);
    const handleForgotClick = (e: MouseEvent) => {
        e.preventDefault();
        setState({
            step: "request_password_reset",
            username: inputRef.current?.value,
            error: void 0
        });
        if (e.target instanceof HTMLAnchorElement) {
            window.history.pushState({}, "", e.target.href);
        }
    };
    return (
        <Fragment key="login-form">
            <Row className="align-up">
                <Logo>{getEnv("package")}</Logo>
            </Row>

            <Row>
                <div className="login-page-form">
                    <Row className="center">
                        <div className="login-input">
                            <input
                                ref={inputRef}
                                autoFocus
                                className="input"
                                placeholder="User name"
                                autoComplete="username"
                                type="text"
                                name="username"
                            />
                        </div>
                    </Row>
                    <Row className="center">
                        <div className="login-input">
                            <input
                                className="input"
                                placeholder="Password"
                                autoComplete="current-password"
                                name="password"
                                type="password"
                            />
                        </div>
                    </Row>
                    {children("Log in")}
                    {isEWS && counter && counter > 0 && (
                        <Row className="center">
                            <div className="forgot-password">
                                <a href="?forgot_password" onClick={handleForgotClick}>
                                    Forgot password?
                                </a>
                            </div>
                        </Row>
                    )}
                </div>
            </Row>
        </Fragment>
    );
}

export default {
    Form: LoginForm,
    handleError: (error: LoginHandlerError, {counter = 0}) => ({
        counter: counter + 1
    })
};
