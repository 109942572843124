import {ReactNode, MouseEvent, ComponentPropsWithoutRef} from "react";
import {Button as RFButton} from "react-foundation";
import {ButtonPropsCommon} from "react-foundation/components/button";

type Props = ButtonPropsCommon &
    ComponentPropsWithoutRef<"div"> & {
        children?: ReactNode;
        type?: string;
        className?: string;
        isSecondary?: boolean;
        disabled?: boolean;
        onClick?: (e: MouseEvent) => void;
    };

export default function Button({
    type = "button",
    isSecondary,
    className,
    ...props
}: Props) {
    const disabled = props.disabled || props.isDisabled;

    if (isSecondary) {
        className = [className, "secondary-button"].filter(x => x).join(" ");
    }

    return (
        <RFButton
            type={type}
            className={className}
            {...props}
            //FIXME: investigate if we can tweak the button styles(?) etc so that we can remove the disabled prop without breaking disabled buttons
            // @ts-expect-error TS(2322) FIXME: Type '{ disabled: boolean | undefined; isDisabled:... Remove this comment to see the full error message
            disabled={disabled}
            isDisabled={disabled}
        />
    );
}
