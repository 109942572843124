const defaults = {
    PACKAGE: "HUB",
    VERSION: "dev",
    NODE_ENV: "production"
};

export default function getEnv(key: $TSFixMe) {
    // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    //eslint-ignore-next-line
    return process.env[key.toUpperCase()] || defaults[key.toUpperCase()];
}
