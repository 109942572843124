import React, {ReactNode, useEffect, useRef} from "react";

type ErrorShakerProps = {
    children?: ReactNode;
    shake?: boolean;
};

export default function ErrorShaker({children, shake}: ErrorShakerProps) {
    const ref = useRef<HTMLParagraphElement>(null);
    useEffect(() => {
        if (!ref.current) return;

        const classes = ref.current.classList;
        if (!classes.contains("shake")) {
            classes.add("shake");
            setTimeout(() => ref.current?.classList?.remove("shake"), 1000);
        }
    });
    return (
        <div className="error-shaker">
            <p ref={ref} className="error">
                {children}
            </p>
        </div>
    );
}
