import "./main.scss";
import {StrictMode} from "react";
import {createRoot, Root} from "react-dom/client";
import getEnv from "lib/getEnv";

import ErrorBoundary from "./components/common/ErrorBoundary";
import Login, {LoginProps} from "components/auth/Login";

import {UIConfig} from "types/ui-config";
import {Profile} from "types/api/auth/whoami";

const defaultOpts: Partial<UIConfig> = {
    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    eventViewDefaultTimezone: "",
    dev: false,
    http2: true,
    envName: "",
    runModes: []
};

const get = async (url: string) => {
    const resp = await fetch(url, {
        credentials: "same-origin"
    });

    if (!resp.ok) {
        throw new Error(resp.statusText);
    }
    return await resp.json();
};

function renderLogin(props: LoginProps, reactRoot: Root) {
    reactRoot.render(
        <StrictMode>
            <ErrorBoundary>
                <Login {...props} />
            </ErrorBoundary>
        </StrictMode>
    );
}

(() => {
    // Some mobile browsers (safari..) include toolbars in the 100vh measure,
    // causing it to be different than actual visible screen area.
    // We need to manually keep track of the actual window height.

    let updateTimeout: $TSFixMe;

    const requestUpdate = () => {
        if (!updateTimeout) {
            updateTimeout = setTimeout(updateScreenHeight, 10);
        }
    };
    const updateScreenHeight = () => {
        clearTimeout(updateTimeout);
        updateTimeout = null;
        document.documentElement.style.setProperty(
            "--window-height",
            `${window.innerHeight}px`
        );
    };

    window.addEventListener("resize", updateScreenHeight);
    window.addEventListener("scroll", requestUpdate);

    updateScreenHeight();

    // disable automatic zoom to form fields on iPhone... alternatively could also use 16px font
    if (navigator.userAgent.match(/iPhone/i)) {
        const viewport = document.querySelector('meta[name="viewport"]')!;
        viewport.setAttribute(
            "content",
            viewport.getAttribute("content") + ", maximum-scale=1"
        );
    }
})();

type UrlOpts = Partial<UIConfig> & {redirect?: string};

(async () => {
    //config loading order: defaults | config.json | urlparams
    const {redirect, ...urlOpts}: UrlOpts = Object.fromEntries(
        new URL(window.location.href).searchParams
    );

    const extconf: UIConfig = await get("./config.json?v2");
    const config: UIConfig = {...defaultOpts, ...extconf, ...urlOpts};
    const reactRoot = createRoot(document.getElementById("app")!);
    document.body.classList.add(getEnv("package").toLowerCase());
    const tryInit = async (profile?: Profile) => {
        try {
            if (!profile) {
                profile = (await get(config.auth + "/whoami")) as Profile;
            }

            if (redirect) {
                const currentURL = new URL(window.location.href);
                const nextURL = new URL(redirect, currentURL);
                if (currentURL.host === nextURL.host) {
                    window.location.href = redirect;
                }
            }
            const module = await import("./renderApp");
            module.default({config, profile}, reactRoot);
        } catch (err) {
            if (err instanceof Error && err.name === "ChunkLoadError") {
                // failure the load the next chunk likely means that the page has update underneath us...
                // best to re-load just in case. ForceGet arg is only supported by FireFox so we need to ignore the ts error
                // @ts-expect-error TS(2554) FIXME: Expected 0 arguments, but got 1.
                window.location.reload(true);
            }
            renderLogin({onLogin: tryInit, authUrl: config.auth}, reactRoot);
        }
    };
    await tryInit();
})();
