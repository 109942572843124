import {Fragment} from "react";

import {Row} from "components/common/Grid";
import Logo from "components/common/Logo";
import getEnv from "lib/getEnv";
import {
    LoginFormValues,
    LoginHandlerError,
    LoginHandlerFormProps,
    LoginProps,
    LoginState
} from "../Login";

function PasswordResetForm({children}: LoginHandlerFormProps) {
    return (
        <Fragment key="reset-form">
            <Row className="align-up">
                <Logo>{getEnv("package")}</Logo>
            </Row>
            <Row className="center description">
                <h4 className="title">Reset Password</h4>
            </Row>
            <Row>
                <div className="login-page-form">
                    <Row className="center">
                        <div className="login-input">
                            <input
                                autoFocus
                                className="input"
                                placeholder="Email or Username"
                                name="username_or_email"
                                type="text"
                            />
                        </div>
                    </Row>
                    <Row className="center">
                        <div className="login-input">
                            <input
                                className="input"
                                placeholder="New password"
                                name="new_password"
                                type="password"
                                autoComplete="new-password"
                            />
                        </div>
                    </Row>
                    <Row className="center">
                        <div className="login-input">
                            <input
                                className="input"
                                placeholder="Enter new password again"
                                name="re_new_password"
                                type="password"
                                autoComplete="new-password"
                            />
                        </div>
                    </Row>
                    {children("Reset password")}
                </div>
            </Row>
        </Fragment>
    );
}

const resetPassword = async (
    authUrl: $TSFixMe,
    {username_or_email, new_password, reset_token, totp}: $TSFixMe
) => {
    let resp;
    try {
        resp = await fetch(`${authUrl}/reset-password`, {
            credentials: "same-origin",
            method: "POST",
            body: JSON.stringify({username_or_email, new_password, reset_token, totp}),
            headers: {
                "Content-Type": "application/json"
            }
        });
    } catch (e) {
        throw {message: "Could not connect to server"};
    }

    if (!resp.ok) {
        const e = await resp.json();
        throw {...e, ...e.errors?.[0], username_or_email, new_password};
    }
};

export default {
    Form: PasswordResetForm,
    getParams: async (
        formVals: LoginFormValues,
        state: LoginState,
        props: LoginProps
    ) => {
        if (formVals.new_password !== formVals.re_new_password) {
            throw "Passwords do not match";
        }

        const username_or_email = formVals.username_or_email;
        const new_password = formVals.new_password;

        if (!username_or_email?.trim()?.length) {
            throw "Username or email is required";
        }

        if (!new_password?.trim()?.length) {
            throw "Empty password not allowed";
        }

        await resetPassword(props.authUrl, {
            username_or_email,
            new_password,
            reset_token: state.reset_token,
            totp: formVals.totp
        });

        const creds = {username: username_or_email, password: new_password};
        if (formVals.totp) {
            // @ts-expect-error TS(2339) FIXME: Property 'totp' does not exist on type '{ username... Remove this comment to see the full error message
            creds.totp = formVals.totp;
        }

        return creds;
    },
    handleError: (error: LoginHandlerError) => {
        const {username_or_email, new_password} = error;
        if (new_password && username_or_email) {
            return {new_password, username_or_email};
        }
    }
};
