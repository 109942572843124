import {Fragment} from "react";

import {Row} from "components/common/Grid";
import Logo from "components/common/Logo";
import {LoginHandlerFormProps, LoginProps, LoginState, LoginFormValues} from "../Login";

import getEnv from "lib/getEnv";

function ForgotPasswordForm({children, username, prevErrorType}: LoginHandlerFormProps) {
    const buttonText = "Request password reset";
    return (
        <Fragment key="request-reset-form">
            <Row className="align-up">
                <Logo>{getEnv("package")}</Logo>
            </Row>
            <Row>
                {prevErrorType !== "password_reset_requested" ? (
                    <div className="login-page-form">
                        <Row className="center">
                            <div className="login-input">
                                <input
                                    autoFocus
                                    className="input"
                                    placeholder="Email or Username"
                                    name="username"
                                    type="text"
                                    defaultValue={username}
                                />
                            </div>
                        </Row>
                        {children(buttonText)}
                    </div>
                ) : (
                    <div className="login-page-form">
                        <Row className="center">
                            <div className="success-container">
                                <i className="arcsec-icon-circle_done" />
                                <div className="text">
                                    <span className="title">
                                        Password reset requested
                                    </span>
                                    Please check your email for further instructions.
                                </div>
                            </div>
                        </Row>
                        <Row />
                    </div>
                )}
            </Row>
        </Fragment>
    );
}

const requestReset = async (authUrl: string, username_or_email: string) => {
    let resp;
    try {
        resp = await fetch(`${authUrl}/request-password-reset`, {
            credentials: "same-origin",
            method: "POST",
            body: JSON.stringify({username_or_email}),
            headers: {
                "Content-Type": "application/json"
            }
        });
    } catch (e) {
        throw {message: "Could not connect to server"};
    }

    if (!resp.ok) {
        const e = await resp.json();
        throw {...e, ...e.errors?.[0]};
    }
};

export default {
    Form: ForgotPasswordForm,
    getParams: async (
        formVals: LoginFormValues,
        state: LoginState,
        props: LoginProps
    ) => {
        const username = formVals.username;

        if (!username || username.trim().length === 0) {
            throw "Username or email is required";
        }

        await requestReset(props.authUrl, username);

        throw {
            type: "password_reset_requested"
        };
    }
};
