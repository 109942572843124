import React, {Component, FormEvent, Fragment, ReactNode} from "react";

import {Row} from "components/common/Grid";
import Button from "components/common/Button";

import LoginForm from "./forms/LoginForm";
import PasswordChangeForm from "./forms/PasswordChangeForm";
import PasswordResetForm from "./forms/PasswordResetForm";
import ForgotPasswordForm from "./forms/ForgotPasswordForm";
import SecondFactorForm from "./forms/SecondFactorForm";
import RegisterForm from "./forms/RegisterForm";
import TosForm from "./forms/TosForm";

import ErrorShaker from "./ErrorShaker";
import {type Profile} from "types/api/auth/whoami";
import {type LoginSuccessResponse} from "types/api/auth/login";

import "./Login.scss";

type HandlerKeys =
    | "login"
    | "2fa"
    | "tos"
    | "register"
    | "password_change"
    | "request_password_reset"
    | "password_reset_requested"
    | "password_reset";

export interface LoginHandlerFormProps extends LoginState {
    submit: (e: {target: HTMLFormElement}) => Promise<void>;
    children: (buttonText: ReactNode) => ReactNode;
    setState: (state: Partial<LoginState>) => void;
}

export interface LoginHandlerError {
    type: HandlerKeys;
    message?: string;
    secret?: string;
    new_password?: string;
    username_or_email?: string;
    contents?: string;
    title?: string;
}

export type LoginFormValues = {
    username?: string;
    password?: string;
    totp?: string;
    new_password?: string;
    re_new_password?: string;
    re_password?: string;
    username_or_email?: string;
    accept?: boolean;
    organization?: string;
};

export type LoginQueryArgs = {
    username?: string;
    password?: string;
    totp?: string;
    accept_tos?: boolean;
};

type LoginHandler = {
    Form: React.FC<LoginHandlerFormProps>;
    handleError?: (
        error: LoginHandlerError,
        state: LoginState
    ) => Partial<LoginState> | void;
    getParams?: (
        formVals: LoginFormValues,
        state: LoginState,
        props: LoginProps
    ) => Promise<LoginQueryArgs>;
};

type LoginHandlers = {
    [key in HandlerKeys]: LoginHandler;
};

const handlers: LoginHandlers = {
    login: LoginForm,
    "2fa": SecondFactorForm,
    // @ts-expect-error TS(2322) FIXME: Type '{ Form: ({ tosContent }: LoginHandlerFormPro... Remove this comment to see the full error message
    tos: TosForm,
    register: RegisterForm,
    // @ts-expect-error TS(2322) FIXME: Type '{ Form: ({ children }: LoginHandlerFormProps... Remove this comment to see the full error message
    password_change: PasswordChangeForm,
    request_password_reset: ForgotPasswordForm,
    password_reset: PasswordResetForm
};

const doLogin = async (authUrl: string, args: LoginQueryArgs): Promise<Profile> => {
    let resp;
    try {
        resp = await fetch(authUrl + "/login", {
            credentials: "same-origin",
            method: "POST",
            body: JSON.stringify(args),
            headers: {
                "Content-Type": "application/json"
            }
        });
    } catch (e) {
        throw {message: "Could not connect to server"};
    }

    if (!resp.ok) {
        let err = {};
        try {
            const e = await resp.json();
            err = {...e, ...e.errors?.[0]};
        } catch (e) {
            if (args.totp) {
                err = {message: "Invalid two factor code", type: "2fa"};
            } else {
                err = {message: "Wrong username or password", type: "password"};
            }
        }

        throw err;
    } else {
        let data: LoginSuccessResponse;
        try {
            data = await resp.json();
        } catch (err) {
            // @ts-expect-error TS(2322) FIXME: Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
            data = {whoami: {role: "viewer", username: args.username}};
        }

        return data.whoami;
    }
};

const defaultGetParams = async (
    formVals: LoginFormValues,
    {username, password}: LoginState
): Promise<LoginQueryArgs> => {
    return {username, password, ...formVals};
};

const refmatch = /[?&]ref=([^&]+)/;
const resetmatch = /[?&]reset_token=([^&]+)/;
const forgotmatch = /[?&]forgot_password/;

export type LoginProps = {
    onLogin: (profile: Profile) => void;
    authUrl: string;
};

export type LoginState = {
    step: HandlerKeys;
    submitting: boolean;
    error?: string;
    prevErrorType?: HandlerKeys;
    previousError?: string;
    referral?: string;
    reset_token?: string;
    counter?: number;
    totp?: string;
    secret?: string;
    username?: string;
    password?: string;
    old_password?: string;
    new_password?: string;
    tosContent?: string;
};

export default class Login extends Component<LoginProps, LoginState> {
    constructor(props: LoginProps) {
        super(props);

        const state: LoginState = {
            step: "login",
            submitting: false,
            error: void 0
        };

        const ref = window.location.search.match(refmatch);
        const reset = window.location.search.match(resetmatch);
        const forgot_password = window.location.search.match(forgotmatch);
        if (ref) {
            state.step = "register";
            state.referral = ref[1];
        } else if (reset) {
            state.step = "password_reset";
            state.reset_token = reset[1];
        } else if (forgot_password) {
            state.step = "request_password_reset";
        }

        this.state = state;
    }

    handleSubmit = async (
        event: FormEvent<HTMLFormElement> | {target: HTMLFormElement}
    ) => {
        // @ts-expect-error TS(2339) FIXME: Property 'preventDefault' does not exist on type '... Remove this comment to see the full error message
        event?.preventDefault?.();

        const target = event.target as HTMLFormElement;

        const {getParams = defaultGetParams, handleError} = handlers[this.state.step];

        const creds = Object.fromEntries(
            new window.FormData(target) as unknown as Iterable<[PropertyKey, string]>
        ) as LoginFormValues;

        this.setState({submitting: true, error: void 0});
        let nextStep: HandlerKeys = this.state.step;
        try {
            const params: LoginQueryArgs = await getParams(creds, this.state, this.props);
            this.setState(prevState => ({...prevState, ...params}));
            // We need to delay changing the step before the async login query is done,
            // otherwise the login field might flash pre-maturely or un-necessarily.
            // The step also needs to be set after the potential queries inside getParams
            // are properly done (password-change) in order to prevent changing the form when
            // validation fails.
            nextStep = "login";
            const profile = await doLogin(this.props.authUrl, params);
            await this.props.onLogin(profile);

            // remove the referral/reset_token part from url after login succeeds
            if (this.state.referral || this.state.reset_token) {
                const url = window.location.href;
                window.history.replaceState(
                    {},
                    "",
                    url.replace(window.location.search, "")
                );
            }

            //clear credentials from state
            this.setState(
                Object.keys(this.state).reduce(
                    (obj, key) => ({...obj, [key]: void 0}),
                    {}
                )
            );
        } catch (err) {
            if (typeof err === "string") {
                this.setState({submitting: false, error: err});
            } else {
                const args = err as unknown as LoginHandlerError;
                this.setState({
                    step: nextStep,
                    submitting: false,
                    prevErrorType: args.type,
                    error: args.message ?? args.title,
                    ...(handleError?.(args, this.state) ?? {}),
                    ...(handlers[args.type]?.handleError?.(args, this.state) ?? {})
                });
            }
        }
    };

    render() {
        const step = this.state.prevErrorType === "2fa" ? "2fa" : this.state.step;
        const Form = handlers[step].Form;
        // @ts-expect-error TS(2345) FIXME: Argument of type 'Partial<LoginState>' is not assi... Remove this comment to see the full error message
        const setState = (nextState: Partial<LoginState>) => this.setState(nextState);
        return (
            <div className={"login-page step-" + step}>
                <div className="grow top" />
                <form onSubmit={this.handleSubmit}>
                    <Form {...this.state} setState={setState} submit={this.handleSubmit}>
                        {(buttonTitle: ReactNode) => (
                            <Fragment>
                                {this.state.error && (
                                    <ErrorShaker>{this.state.error}</ErrorShaker>
                                )}
                                <Row className="center">
                                    <Button
                                        type="submit"
                                        isDisabled={this.state.submitting}
                                    >
                                        <strong>{buttonTitle}</strong>
                                    </Button>
                                </Row>
                            </Fragment>
                        )}
                    </Form>
                </form>
                <div className="grow bottom" />
            </div>
        );
    }
}
