import {Fragment, useRef, useState, useEffect} from "react";

import {Row} from "components/common/Grid";
import Button from "../../common/Button";
import {
    LoginFormValues,
    LoginHandlerError,
    LoginHandlerFormProps,
    LoginState
} from "components/auth/Login";

function TosForm({tosContent}: LoginHandlerFormProps) {
    const [isChecked, setChecked] = useState(false);
    const frame = useRef<HTMLIFrameElement>(null);
    const handleCheck = () => setChecked(!isChecked);

    const updateSize = () => {
        // @ts-expect-error TS(2531) FIXME: Object is possibly 'null'.
        frame.current.height =
            // @ts-expect-error TS(2531) FIXME: Object is possibly 'null'.
            Number(frame.current.contentWindow.document.documentElement.scrollHeight) + 1;
    };

    useEffect(() => {
        window.addEventListener("resize", updateSize);
        return () => window.removeEventListener("resize", updateSize);
    }, []);

    return (
        <Fragment key="tos">
            <Row className="tos-form">
                <iframe
                    srcDoc={tosContent}
                    ref={frame}
                    sandbox="allow-same-origin"
                    onLoad={updateSize}
                />
                <div className="tos-fields">
                    <input
                        type="checkbox"
                        name="accept"
                        id="accept-box"
                        checked={isChecked}
                        onChange={handleCheck}
                    />
                    <label htmlFor="accept-box">I accept the Terms of Service</label>
                </div>
                <Row className="center">
                    <Button type="submit" isDisabled={!isChecked}>
                        <strong>Continue</strong>
                    </Button>
                </Row>
            </Row>
        </Fragment>
    );
}

export default {
    Form: TosForm,
    getParams: (formVal: LoginFormValues, {password, username}: LoginState) => {
        if (formVal.accept) {
            return {username, password, accept_tos: true};
        }
    },
    handleError: (error: LoginHandlerError) => {
        if (error.type === "tos") {
            return {
                step: "tos",
                error: void 0,
                tosContent: error.contents
            };
        }
    }
};
