import {Component, createContext, ContextType, ReactNode, ErrorInfo} from "react";
import {ApolloClient, gql} from "@apollo/client";

import "./ErrorBoundary.scss";

const emptyState = {error: void 0, info: void 0};

type ErrorLoggerContextType = {
    logError?: (args: LogErrorVariables) => void;
};
const ErrorLoggerContext = createContext<ErrorLoggerContextType>({});

type ErrorBoundaryProps = {
    className?: string;
    errorText?: ReactNode;
    children?: ReactNode;
};

type ErrorBoundaryState = {
    prevProps: Partial<ErrorBoundaryProps>;
    error?: Error;
    info?: ErrorInfo;
};
/*
* ErrorBoundary that catches and shows errors that happen within the child component tree.
* If the boundary is located within <ErrorLoggerProvider>, the stack traces will be logged to web-backend.
*
* Usage:
* <ErrorLoggerProvider logError={createErrorLogger(apolloClient)}>
*   <ErrorBoundary>
        <SomeComponent/>
*   </ErrorBoundary>
* </ErrorLoggerProvider>
* */

export default class ErrorBoundary extends Component<
    ErrorBoundaryProps,
    ErrorBoundaryState
> {
    static contextType = ErrorLoggerContext;
    context!: ContextType<typeof ErrorLoggerContext>;

    static displayName = "ErrorBoundary";

    state: ErrorBoundaryState = {...emptyState, prevProps: {}};

    componentDidCatch(error: Error, info: ErrorInfo) {
        this.setState({error, info});
        if (this.context && "logError" in this.context) {
            let stack = error.stack ?? "";
            if (stack.indexOf(error.message) === -1) {
                // firefox and safari don't have the error message in stack
                stack = `${error.message}\n${stack}`;
            }

            this.context.logError!({
                stack,
                componentStack: info.componentStack
            });
        }
    }

    static getDerivedStateFromProps(
        nextProps: ErrorBoundaryProps,
        _prevState: ErrorBoundaryState
    ) {
        const {prevProps, ...prevState} = _prevState;

        if (
            Object.keys(prevProps).length === Object.keys(nextProps).length &&
            Object.keys(prevProps).every(
                key =>
                    key in nextProps &&
                    prevProps[key as keyof typeof prevProps] ===
                        nextProps[key as keyof typeof nextProps]
            )
        ) {
            return null;
        }

        if (prevState.error) {
            return {...emptyState, prevProps: nextProps};
        }

        return {prevProps: nextProps};
    }

    render() {
        const {className = "", children, errorText} = this.props;

        if (this.state.info) {
            return (
                <div className={"error-boundary " + className}>
                    {errorText || <h2>Something went wrong :(</h2>}
                    <details style={{whiteSpace: "pre-wrap"}}>
                        {this.state.error && this.state.error.toString()}
                        <br />
                        {this.state.info.componentStack}
                    </details>
                </div>
            );
        }

        return children;
    }
}

type LogErrorVariables = {
    stack: string;
    componentStack: string;
};

const LogErrorMutation = gql`
    mutation LogError($stack: String, $componentStack: String) {
        logError(stack: $stack, componentStack: $componentStack)
    }
`;

export function createErrorLogger(apolloClient: ApolloClient<unknown>) {
    return (variables: LogErrorVariables) =>
        apolloClient.mutate({
            mutation: LogErrorMutation,
            variables
        });
}

type ErrorLoggerProviderProps = {
    logError?: (args: LogErrorVariables) => void;
    children: ReactNode;
};

export function ErrorLoggerProvider({logError, children}: ErrorLoggerProviderProps) {
    return (
        <ErrorLoggerContext.Provider value={{logError}}>
            {children}
        </ErrorLoggerContext.Provider>
    );
}
